.main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  background-image: url('../../assets/wanna-play.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size:contain;
}
.button {
  background: none;
  background-color: rgba(137, 2, 2, 0.403);
  color: rgb(255, 255, 255, 0.703);
  font-size: 3vh;
  font-weight: 600;
  padding: 2vh 6vh;
  margin-top: 60vh;
  text-decoration: none;
  &:hover {
    background-color: hsla(0%, 0%, 0%, .5);
    color: 'black';
  }
}