.email {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(1, 1, 55, 0.4);
    width: 100%;
    height: 100vh;
    z-index: 2;
    position: relative;
}

.emailInner {
    margin-top: 40px;
    background-color: rgba(1, 1, 55, 0.4);
    padding: 30px 40px;
    width: 60%;
    max-width: 400px;
}

.heading {
    font-size: 20px;
    margin-bottom: 40px;
    margin-top: 0;
    width: 100%;
}